'use strict';

Gri.module({
  name: 'brand-box-alpha',
  ieVersion: null,
  $el: $('.brand-box-alpha'),
  container: '.brand-box-alpha',
  fn: function () {
    
  }
});
